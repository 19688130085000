<script setup lang="ts">
import {computed} from 'vue'
import {useCompanyStore} from '../../stores/company'
import RBButton from '../Button.vue'
import {storeToRefs} from 'pinia'

const MAX_CHARACTER = 30

interface Props {
  variant?: 'normal' | 'invert'
}

const companyStore = useCompanyStore()
const {name: hotelName} = storeToRefs(companyStore)

const props = withDefaults(defineProps<Props>(), {
  variant: 'normal',
})

const isTextXs = computed(() => hotelName.value && hotelName.value.length > MAX_CHARACTER)

const buttonClasses = computed(() => ({
  'flex flex-row items-center': props.variant === 'normal',
  'flex flex-row-reverse items-center': props.variant === 'invert',
}))

const hotelNameClasses = computed(() => ({
  'text-base flex line-clamp-2 max-w-full': true,
  'text-xs': props.variant === 'normal' && isTextXs.value,
  'text-right': props.variant === 'normal',
  'text-left': props.variant === 'invert',
}))
</script>

<template>
  <button :class="buttonClasses">
    <RBButton class="!w-full" variant="custom">
      <div :class="hotelNameClasses">
        <span>{{ hotelName }}</span>
      </div>
    </RBButton>

    <div>
      <div class="!w-10 !h-10 bg-white shadow-md rounded-full flex items-center justify-center">
        <i class="fa-solid fa-hotel text-gray-600 text-lg"></i>
      </div>
    </div>
  </button>
</template>
