import type AbortControllerService from './AbortControllerService'

export default class AbortServicesHandler {
  controller?: AbortController
  services: AbortControllerService[]

  constructor(...services: AbortControllerService[]) {
    this.services = services
  }

  abort() {
    this.controller?.abort()
  }

  setAbortController() {
    this.controller = new AbortController()
    this.services.forEach((s) => (s.controller = this.controller))
  }
}
