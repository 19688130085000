<script setup lang="ts">
import {ref, watch} from 'vue'
import {useLoadingStore} from '../stores/loading'

const loadingStore = useLoadingStore()

interface Props {
  visible: boolean
}

const props = withDefaults(defineProps<Props>(), {
  visible: true,
})

const isVisible = ref(props.visible)

watch(
  () => loadingStore.isLoading,
  function (val) {
    isVisible.value = val
  }
)
</script>

<template>
  <transition
    enter-active-class="duration-700 ease-in-out"
    enter-from-class="-translate-y-1/2 scale-y-0 opacity-0"
    enter-to-class="translate-y-0 scale-y-100 opacity-100"
    leave-active-class="duration-700 ease-in-out"
    leave-from-class="translate-y-0 scale-y-100 opacity-100"
    leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
  >
    <div v-if="isVisible" class="absolute w-full h-[5px] bg-brandmain bg-opacity-40 overflow-x-hidden">
      <div class="absolute bg-branddark h-[5px] animate-increasing"></div>
    </div>
  </transition>
</template>
