<script setup lang="ts">
import {computed} from 'vue'
import {useNotificationsStore} from '../../stores/notifications'

const notificationsStore = useNotificationsStore()

const amountOfNotifications = computed(() => {
  return notificationsStore.notifications.length
})

function handleOnClick() {
  notificationsStore.showSidePanel = !notificationsStore.showSidePanel
  notificationsStore.showBanner = false
}
</script>

<template>
  <div class="relative cursor-pointer" @click="handleOnClick">
    <i class="fa-solid fa-bell text-3xl mt-1 text-white"></i>
    <div v-if="amountOfNotifications !== 0" class="amount-indicator">
      <span class="text-white">{{ amountOfNotifications }}</span>
    </div>
  </div>
</template>

<style scoped>
.amount-indicator {
  @apply flex justify-center items-center
  rounded-xl text-xs bg-red
  absolute -right-1.5 top-0
  w-[1rem] h-[1rem];
}
</style>
