<script setup lang="ts">
import {computed} from 'vue'
import NotificationErrorIcon from './NotificationErrorIcon.vue'
import {useNotificationsStore} from '../../stores/notifications'
import {useI18n} from 'vue-i18n'
import notificationPayloadService from '../../services/Notification/NotificationPayloadService'
import notificationTagService from '../../services/Notification/NotificationTagService'
import {useDateTimeFormatter} from '../../composable/useDateTimeFormatter'
import {useRouter} from 'vue-router'

interface Props {
  id: number
  tag: string
  payload: string
  type: string
  dateTime: Date
}

const notificationsStore = useNotificationsStore()
const {t} = useI18n()
const router = useRouter()
const [CustomFormatter] = useDateTimeFormatter({month: 'short', day: 'numeric'}, true)
const props = defineProps<Props>()

const formattedDate = computed(() => CustomFormatter.value.format(props.dateTime))

const formattedPayload = computed(() => notificationPayloadService.formatPayload({tag: props.tag, payload: props.payload}, t))
const formattedTag = computed(() => {
  const translationKey = notificationTagService.getTagTranslationKey({notificationType: props.type, payload: props.payload})

  return translationKey ? t(translationKey) : props.tag
})

// in the future this function will have to contain more logic
async function handleSolveNow() {
  const notificationType = props.type
  await notificationsStore.removeNotification(props.id)
  notificationsStore.showSidePanel = false
  if (notificationType === 'ROOMTYPE') {
    await router.push('/settings/inventory/config')
  } else {
    await router.push('/settings/segmentation')
  }
}
</script>

<template>
  <div class="flex flex-wrap justify-end w-full">
    <div class="flex w-full">
      <div class="w-[10%] flex justify-center items-center">
        <NotificationErrorIcon class="mr-1.5" />
      </div>
      <div class="w-[65%] text-red font-bold">
        <p class="text-white text-md">{{ formattedTag }}</p>
      </div>
      <div class="w-[25%] text-center">
        <span class="solve-now-text" @click="handleSolveNow">
          {{ t('notifications.solveNow') }}
        </span>
      </div>
    </div>
    <div class="w-[90%] flex flex-wrap text-grey mt-0.5">
      <p class="w-full">{{ formattedPayload }}</p>
      <span class="text-sm text-darkgrey">{{ formattedDate }}</span>
    </div>
  </div>
</template>

<style scoped>
.solve-now-text {
  @apply text-sm text-lightgrey font-semibold
  cursor-pointer uppercase hover:text-white;
}
</style>
