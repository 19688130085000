<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {storeToRefs} from 'pinia'
import {useI18n} from 'vue-i18n'
import type {Company} from '../services/CompanyService'
import type {UserSettings} from '../services/UsersService'
import {useCompaniesStore} from '../stores/companies'
import {useGlobalStore} from '../stores/global'
import {useModalStore} from '../stores/modal'
import {useToastStore} from '../stores/toast'
import {useUserStore} from '../stores/user'
import ModalDialogContent from './ModalDialogContent.vue'
import RBSelect from './Select.vue'
import {isNullOrUndefined} from '../utils/utils'
import CompanyPropertiesCookie from '../utils/CompanyPropertiesCookie'
import {addDefaultErrorToast} from '../utils/toast'

const EXCLUDED_ROUTES = ['/change-password', '/contract']

const route = useRoute()
const router = useRouter()
const {t} = useI18n()

const companiesStore = useCompaniesStore()
const globalStore = useGlobalStore()
const modalStore = useModalStore()
const toastStore = useToastStore()
const userStore = useUserStore()
const {sortedActiveCompanies} = storeToRefs(companiesStore)

const modalId = ref<string>()
const selectedCompanyId = ref<number | null>(null)

const isCompanySelected = computed(() => selectedCompanyId.value !== null)

onMounted(handelDefaultCompanyId)
watch(() => userStore.settings.defaultCompanyId, handelDefaultCompanyId)

async function handelDefaultCompanyId() {
  try {
    await router.isReady()
    await globalStore.initialize()
    await userStore.initialize()
  } catch (error) {
    errorHandling(error)
  }

  if (isNullOrUndefined(userStore.settings.defaultCompanyId)) {
    if (!EXCLUDED_ROUTES.includes(route.path) && !('status' in route.query)) {
      setDefaultCompanyId()
    }
  }
}

async function setDefaultCompanyId() {
  try {
    await companiesStore.initialize()
  } catch (error) {
    errorHandling(error)
  }

  if (companiesStore.sortedActiveCompanies.length > 1) {
    modalId.value = modalStore.addModal(t('hotelSelection.title'), t('hotelSelection.message'), {
      callback: onSave,
      enabled: isCompanySelected,
    })
  }
}

async function onSave() {
  const settings: UserSettings = {...userStore.settings, defaultCompanyId: selectedCompanyId.value}
  await userStore.updateUserSettings(settings).catch(errorHandling)

  CompanyPropertiesCookie.remove()
  window.location.replace('/')
}

function errorHandling(error: any) {
  addDefaultErrorToast(toastStore, t, error)
  throw error
}

function valueFormatter(company: Company) {
  return company.name
}
</script>

<template>
  <ModalDialogContent :id="modalId">
    <RBSelect
      class="mt-4 w-96"
      :compare-callback="(item: Company, value: number) => item.id === value"
      :items="sortedActiveCompanies"
      :value="selectedCompanyId"
      :value-formatter="valueFormatter"
      @change="(company: Company) => (selectedCompanyId = company.id)"
    ></RBSelect>
  </ModalDialogContent>
</template>
