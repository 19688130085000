<script setup lang="ts">
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'
import type {Maybe} from '../types'

type Emits = {
  change: [value: any]
}

interface Props {
  items: Maybe<ReadonlyArray<any>>
  value?: Maybe<any>
  disabled?: boolean
  valueFormatter?: (item: any) => string
  compareCallback?: (item: any, value: any) => boolean
  selectPlaceHolder?: Maybe<string>
  customStyle?: string
}

const {t} = useI18n()

const emits = defineEmits<Emits>()

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  valueFormatter: (item: any) => String(item),
  compareCallback: (item: any, value: any) => item === value,
  selectPlaceHolder: null,
})

const isEmpty = computed(() => (props.items ? props.items.length === 0 : true))
const isDisabled = computed(() => props.disabled || isEmpty.value)
const placeHolder = computed(() => (isEmpty.value ? t('select.empty') : props.selectPlaceHolder || t('select.notSet')))
const classes = computed(() => ({'cursor-not-allowed': isDisabled.value}))

function onInput(event: Event) {
  const target = event.target as HTMLSelectElement | null
  if (props.items && target) emits('change', props.items[target.value as unknown as number])
}
</script>

<template>
  <div
    class="relative w-full px-2 py-1 bg-brandlight hover:bg-brandlightdarker flex items-center overflow-hidden"
    :class="customStyle"
    data-testid="selectContainer"
  >
    <label class="text-base"><i class="fa-solid fa-angle-down text-sm text-brandmain"></i></label>
    <select
      :class="classes"
      :disabled="isDisabled"
      @input="onInput"
      class="absolute w-full pl-4 flex-grow text-base text-branddark bg-transparent h-full"
    >
      <option v-if="value === null || value === undefined" disabled selected>{{ placeHolder }}</option>
      <option v-for="(item, idx) in items" :key="idx" :value="idx" :selected="compareCallback(item, value)">
        {{ valueFormatter(item) }}
      </option>
    </select>
  </div>
</template>
