<script setup lang="ts">
import {ref, computed, onMounted} from 'vue'
import {useCompanyStore} from '../../stores/company'
import {useGlobalStore} from '../../stores/global'
import type {Company} from '../../services/CompanyService'
import CompanyPropertiesCookie from '../../utils/CompanyPropertiesCookie'
import {CompanyService} from '../../services/CompanyService'
import delayWrapper from '../../utils/delayWrapper'
import AbortServicesHandler from '../../services/abort/AbortServicesHandler'
import {silenceAbortError} from '../../services/abort/AbortControllerService'

const ASCENDING = true
const FILTER_THRESHOLD = 15
const IS_DIGIT_REGEX = /^-?\d+$/
const PAGE_SIZE = 15
const SORT_BY_KEY = 'name'

interface Props {
  light?: boolean
  scrollable?: boolean
}

const companyStore = useCompanyStore()
const globalStore = useGlobalStore()

const companyService = new CompanyService()
const abortServicesHandler = new AbortServicesHandler(companyService)

const props = withDefaults(defineProps<Props>(), {
  light: true,
  scrollable: true,
})

const isFetchingData = ref(false)
const fetchedCompanies = ref<Company[]>()
const totalCompaniesAssigned = ref(0)
const hotelFilter = ref('')

const activeCompanies = computed(() => fetchedCompanies.value?.filter((c) => c.isActive) ?? [])
const hotelsWithoutCurrent = computed(() => activeCompanies.value.filter((c) => c.id !== companyStore.id))

const showFilter = computed(() => globalStore.hasAuthority('ADMIN') || totalCompaniesAssigned.value > FILTER_THRESHOLD)
const showList = computed(() => hotelsWithoutCurrent.value.length >= 1)

const filterClasses = computed(() => ({
  'w-full px-3 py-0.5 text-black bg-brandverylight': true,
  'outline-none border-2 border-brandlightdarker focus:border-brandmain hover:border-brandmain': true,
}))

const hotelClasses = computed(() => ({
  'text-brandlight hover:text-white': !props.light,
  'text-brandmain hover:text-branddark': props.light,
}))

const hotelListClasses = computed(() => ({
  'h-full': !props.scrollable,
  'max-h-[200px] overflow-y-auto': props.scrollable,
}))

const selectedHotelClasses = computed(() => ({
  'text-brandlight hover:text-white font-bold': !props.light,
  'text-branddark font-bold': props.light,
}))

onMounted(() => {
  if (globalStore.hasAuthority('ADMIN')) return
  fetchPaginatedCompanies(undefined, undefined, true)
})

const delayedOnHandleInput = delayWrapper(onHandleInput, 1000, true)

function fetchPaginatedCompanies(ids?: number[], nameLike?: string, initialFetch = false) {
  abortServicesHandler.setAbortController()
  isFetchingData.value = true
  companyService
    .fetchPaginated(0, PAGE_SIZE, SORT_BY_KEY, ASCENDING, ids, nameLike)
    .then((response) => {
      fetchedCompanies.value = response.content

      if (initialFetch) {
        totalCompaniesAssigned.value = response.totalElements
      }
    })
    .catch(silenceAbortError)
    .finally(() => (isFetchingData.value = false))
}

function onHandleInput() {
  abortServicesHandler.abort()
  if (IS_DIGIT_REGEX.test(hotelFilter.value)) {
    fetchPaginatedCompanies([parseInt(hotelFilter.value)])
  } else {
    fetchPaginatedCompanies(undefined, hotelFilter.value.trim() || undefined)
  }
}

async function switchHotelId(company: Company) {
  CompanyPropertiesCookie.set(company)
  window.location.replace('/')
}
</script>

<template>
  <div class="w-full flex flex-col gap-y-4">
    <div v-if="showFilter">
      <input
        v-model="hotelFilter"
        :class="filterClasses"
        :placeholder="`${$t('accountDropdown.filter')} ...`"
        @input="delayedOnHandleInput"
      />
    </div>

    <span :class="selectedHotelClasses">{{
      globalStore.hasAuthority('ADMIN') ? `${companyStore.name} (${companyStore.id})` : companyStore.name
    }}</span>

    <div v-if="isFetchingData">
      <span>{{ `${$t('accountDropdown.loading')} ...` }}</span>
    </div>
    <div v-else-if="showList" class="flex flex-col gap-y-2" :class="hotelListClasses">
      <div v-for="hotel in hotelsWithoutCurrent" :key="hotel.id" :class="hotelClasses">
        <button type="button" @click="switchHotelId(hotel)" class="text-left">
          {{ globalStore.hasAuthority('ADMIN') ? `${hotel.name} (${hotel.id})` : hotel.name }}
        </button>
      </div>
    </div>
  </div>
</template>
