<script setup lang="ts">
import {computed, ref} from 'vue'

interface Props {
  forceOpen: boolean
  linkClasses: string | string[] | object
  title: string
}

const props = defineProps<Props>()

const state = ref(false)
const isExpanded = computed(() => props.forceOpen || !!state.value)

const iconClasses = computed(() => ({
  'fa-solid fa-angle-down text-[80%] transition-all duration-700 ease-in-out': true,
  'rotate-0': props.forceOpen || !isExpanded.value,
  'rotate-180': !props.forceOpen && isExpanded.value,
}))

function onClick() {
  if (props.forceOpen) return
  state.value = !state.value
}
</script>

<template>
  <a @click="onClick" :class="linkClasses" class="whitespace-nowrap">
    <i :class="iconClasses"></i>
    {{ title }}
  </a>

  <transition name="expand">
    <div v-if="isExpanded" class="bg-inherit">
      <slot></slot>
    </div>
  </transition>
</template>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  @apply transition-all duration-500 ease-in-out;
}

.expand-enter-from,
.expand-leave-to {
  @apply opacity-0 max-h-[0px] scale-100 -translate-y-[10px] overflow-hidden;
}
</style>
