<script setup lang="ts">
import {ref} from 'vue'
import {useNotificationsStore} from '../../stores/notifications'
import HotelFilter from './HotelFilter.vue'
import LogoutProfileMenu from './LogoutProfileMenu.vue'
import CompanyButton from './CompanyButton.vue'

const notificationsStore = useNotificationsStore()

let toggleTimeoutId: number | undefined = undefined
const showDialog = ref(false)

function onMouseEnter() {
  notificationsStore.showSidePanel = false
  toggleTimeoutId = window.setTimeout(() => {
    setTimeout(() => {
      toggleTimeoutId = undefined
    }, 500)

    showDialog.value = true
  }, 200)
}

function onMouseLeave() {
  window.clearTimeout(toggleTimeoutId)
  toggleTimeoutId = undefined

  showDialog.value = false
}

function toggleHandler() {
  if (showDialog.value && toggleTimeoutId != null) return
  showDialog.value = !showDialog.value
}
</script>

<template>
  <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <CompanyButton @click="toggleHandler" class="h-20 max-w-[350px]"></CompanyButton>

    <transition
      enter-active-class="duration-75 ease-in-out"
      enter-from-class="-translate-y-1/4 scale-y-0 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="duration-75 ease-in-out"
      leave-from-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="-translate-y-1/4 scale-y-0 opacity-0"
    >
      <template v-if="showDialog">
        <div class="absolute right-0 w-72 flex flex-col gap-y-5 p-5 bg-white text-base shadow-md z-header">
          <LogoutProfileMenu></LogoutProfileMenu>
          <hr class="w-full border-t border-gray-300" />
          <HotelFilter></HotelFilter>
        </div>
      </template>
    </transition>
  </div>
</template>
