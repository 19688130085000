import {defineStore} from 'pinia'
import UserService from '../services/UsersService'
import {useGlobalStore} from './global'
import i18n, {browserLanguage, browserLocale} from '../i18n'
import {useCookies} from 'vue3-cookies'
import UsersService from '../services/UsersService'
import type {UserSettings, UserProperties} from '../services/UsersService'

const rbLanguageMapping: {[key: string]: 'en' | 'de' | 'it'} = {
  EN: 'en',
  IT: 'it',
  DE: 'de',
}

export const rbLocaleMapping: {[key: string]: string} = {
  US: 'en',
  GB: 'en-gb',
  IT: 'it',
  DE: 'de',
  AT: 'de-at',
  CH: 'de-ch',
  FR: 'fr',
  FI: 'fi',
}

type State = {
  initialized: boolean
  properties: UserProperties
}

export const useUserStore = defineStore('user', {
  state(): State {
    return {
      initialized: false,
      properties: {
        id: null,
        email: null,
        username: null,
        firstname: null,
        lastname: null,
        termsAccepted: null,
        settings: {
          language: null,
          locale: null,
          showCurrencySymbol: true,
          rateReport: 'NEVER',
          pickupReport: 'NEVER',
          performanceReport: 'NEVER',
          defaultCompanyId: null,
        },
      },
    }
  },
  actions: {
    __mapUserProperties(properties: UserProperties) {
      this.properties = properties
      this.__setUserLanguage()
    },
    __mapUserSettings(settings: UserSettings) {
      this.properties.settings = settings
      this.__setUserLanguage()
    },
    __setUserLanguage() {
      if (this.settings && this.settings.language && this.settings.language in rbLanguageMapping) {
        i18n.global.locale.value = rbLanguageMapping[this.settings.language]
      }

      // store user's language and locale in a cookie for old front-end
      useCookies().cookies.set('userLanguage', this.language.toLowerCase(), 0)
      useCookies().cookies.set('userLocale', this.locale.toLowerCase(), 0)
    },
    getUserProperties() {
      const globalStore = useGlobalStore()
      if (globalStore.selectedUserId != null) {
        return UserService.fetchUser(globalStore.selectedUserId).then((response) => this.__mapUserProperties(response))
      } else return Promise.reject(new Error('Error while fetching user properties'))
    },
    async initialize() {
      if (!this.initialized) {
        await this.getUserProperties().then(() => (this.initialized = true))
      } else {
        this.__setUserLanguage()
      }
    },
    updateUserSettings(settings: UserSettings) {
      const globalStore = useGlobalStore()
      if (globalStore.selectedUserId != null) {
        return UsersService.updateUserSettings(globalStore.selectedUserId, settings).then((response) => {
          this.__mapUserSettings(response)
        })
      } else return Promise.reject(new Error('Error while updating user properties: No user active!'))
    },
  },
  getters: {
    id: (state: State) => state.properties.id,
    email: (state: State) => state.properties.email,
    username: (state: State) => state.properties.username,
    settings: (state: State) => state.properties.settings,
    language: (state: State) => {
      const key = state.properties.settings && state.properties.settings.language
      return (key && rbLanguageMapping[key]) || browserLanguage
    },
    locale: (state: State) => {
      const key = state.properties.settings && state.properties.settings.locale
      return (key && rbLocaleMapping[key]) || browserLocale
    },
  },
})
