import {defineStore} from 'pinia'
import type {Company} from '../services/CompanyService'
import CompanyService from '../services/CompanyService'

interface State {
  companies: Company[]
  initialized: boolean
  isLoading: boolean
}

const defaultState: State = {
  companies: [],
  initialized: false,
  isLoading: false,
}

export const useCompaniesStore = defineStore('companies', {
  state: () => {
    return {...defaultState}
  },
  actions: {
    async initialize() {
      if (!this.initialized) {
        await this.load()
      }
    },
    load() {
      this.isLoading = true
      return CompanyService.fetchAll()
        .then((response) => {
          this.companies = response
          this.initialized = true
        })
        .catch((error) => {
          this.reset()
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    reset() {
      Object.assign(this, defaultState)
    },
  },
  getters: {
    sortedActiveCompanies: (state) => {
      return state.companies ? state.companies.filter((c) => c.isActive).sort((a, b) => a.name.localeCompare(b.name)) : []
    },
  },
})
