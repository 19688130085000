import type {Page} from '../types/pagination'
import type {Nullable} from '../types'
import type SupportedCurrencies from '../constants/SupportedCurrencies'
import type SupportedTimeZones from '../constants/SupportedTimeZones'
import {defaultApi} from '../axios'
import {isNullOrUndefined} from '../utils/utils'
import AbortControllerService from './abort/AbortControllerService'

export type SupportedCurrency = (typeof SupportedCurrencies)[number]
export type SupportedTimeZone = (typeof SupportedTimeZones)[number]

export interface Address {
  address?: Nullable<string>
  city?: Nullable<string>
  countryCode?: Nullable<string>
  zip?: Nullable<string>
}

export interface DataPrivacyAgreement {
  accepted: boolean
  signature?: Nullable<string>
}

interface Attributes {
  area?: Nullable<string>
  country?: Nullable<string>
  mainFocus1?: Nullable<string>
  mainFocus2?: Nullable<string>
  openingPeriod?: Nullable<string>
  season?: Nullable<string>
  segment?: Nullable<string>
  stars?: Nullable<string>
}

export interface Company {
  id: number
  name: string
  address?: Nullable<Address>
  attributes?: Nullable<Attributes>
  createdOn?: Nullable<Date>
  currency?: Nullable<SupportedCurrency>
  dataPrivacyAgreement?: Nullable<DataPrivacyAgreement>
  hubspotId?: Nullable<number>
  isActive?: Nullable<boolean>
  mainUserId?: Nullable<number>
  parentCompanyName?: Nullable<string>
  timeZone?: Nullable<SupportedTimeZone>
  trustYouId?: Nullable<string>
}

export interface HubspotCompany {
  properties: HubspotCompanyProperties
}

interface HubspotCompanyProperties {
  address?: string
  app_autopilot_active: boolean
  app_market_trends_active: boolean
  area?: string
  city?: string
  country_?: string
  country_code?: string
  frequent_zyte_crawling: boolean
  hq_plus_api_key?: string
  is_active: boolean
  main_focus_1?: string
  main_focus_2?: string
  name?: string
  opening_period?: string
  parent_company_name?: string
  rateboard_hotel_id?: number
  season?: string
  segment?: string
  stars?: string
  zip?: string
}

export interface Features {
  companyId: number
  autopilot: boolean
  marketTrends: boolean
}

function mapCompanyResponse(company: Company) {
  if (!isNullOrUndefined(company.createdOn)) {
    company.createdOn = new Date(company.createdOn)
  }
  return company
}

export class CompanyService extends AbortControllerService {
  create(company: Partial<Company>): Promise<Company> {
    return defaultApi.post<Company>(`/api/company/companies`, company).then((res) => mapCompanyResponse(res.data))
  }

  fetchAll(): Promise<Company[]> {
    return defaultApi.get<Company[]>('/api/company/companies', this.buildConfig()).then((res) => res.data.map(mapCompanyResponse))
  }

  fetchFeatures(companyId: number): Promise<Features> {
    return defaultApi.get<Features>(`/api/company/features/${companyId}`, this.buildConfig()).then((res) => res.data)
  }

  fetchFirst(): Promise<Company | undefined> {
    return this.fetchPaginated(0, 1).then((res) => res.content.at(0))
  }

  fetchHubspotCompany(hubspotId: number): Promise<HubspotCompany> {
    return defaultApi.get<HubspotCompany>(`/api/company/hubspot-companies/${hubspotId}`, this.buildConfig()).then((res) => res.data)
  }

  fetchPaginated(
    page: number,
    size: number,
    sort?: string,
    ascending?: boolean,
    ids?: number[],
    nameLike?: string
  ): Promise<Page<Company>> {
    const joinedIds = ids && ids.join(',')
    const sortWithDirection = sort ? `${sort},${ascending ? 'asc' : 'desc'}` : undefined
    const params = {page, size, sort: sortWithDirection, ids: joinedIds, nameLike}
    return defaultApi.get<Page<Company>>('/api/company/companies', this.buildConfig(params)).then((res) => {
      res.data.content = res.data.content.map(mapCompanyResponse)
      return res.data
    })
  }

  fetchSingle(companyId: number): Promise<Company> {
    return defaultApi
      .get<Company>(`/api/company/companies/${companyId}`, this.buildConfig())
      .then((res) => mapCompanyResponse(res.data))
  }

  update(company: Partial<Company>): Promise<Company> {
    return defaultApi.put(`/api/company/companies/${company.id}`, company).then((res) => mapCompanyResponse(res.data))
  }
}

export default new CompanyService()
