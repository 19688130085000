<script setup lang="ts">
import {computed} from 'vue'
import {useGlobalStore} from '../../stores/global'

type Emit = {
  toggleMenu: []
}

interface Props {
  light?: boolean
}

const globalStore = useGlobalStore()
defineEmits<Emit>()
const props = withDefaults(defineProps<Props>(), {
  light: true,
})

const profileClasses = computed(() => ({
  'text-brandmain hover:text-branddark': props.light,
  'text-brandlightdarker hover:text-white': !props.light,
}))

const logoutClasses = computed(() => ({
  'text-lightred hover:text-branddark': props.light,
  'text-lightred hover:text-white': !props.light,
}))

function onClickLogout() {
  window.location.replace('/rateboard/logout')
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div v-show="globalStore.hasAuthority('VIEWER') || globalStore.hasAuthority('TESTING')">
      <router-link to="/settings/user" :class="profileClasses" @click="$emit('toggleMenu')">
        <i aria-hidden="true" class="fa fa-user pr-2"></i>
        <span class="uppercase">{{ $t('accountDropdown.profile') }}</span>
      </router-link>
    </div>

    <div>
      <button @click="onClickLogout" :class="logoutClasses">
        <i aria-hidden="true" class="fa fa-sign-out-alt pr-2"></i>
        <span class="uppercase">{{ $t('logout') }}</span>
      </button>
    </div>
  </div>
</template>
