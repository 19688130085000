import {createApp} from 'vue'
import App from './App.vue'
import i18n from '../../i18n'
import pinia from '../../pinia'
import router from '../../router'
import floatingvue from 'floating-vue'
import '../../assets/css/index.css'
import {floatingVueOptions, floatingVueTooltipDirectives} from '../../custom-floating-vue'
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'

import * as Sentry from '@sentry/vue'
import {CaptureConsole} from '@sentry/integrations'
import {createGtm} from '@gtm-support/vue-gtm'
import VueHotjar from 'vue-hotjar-next'
import {useCompanyStore} from '../../stores/company'
import {useGlobalStore} from '../../stores/global'
import {useUserStore} from '../../stores/user'
import {getEnvironment} from '../../utils/getEnvironment'
import {interceptorsSetup} from '../../axios'
import {version as currentVersion} from '../../../package.json'
import '../../vee-validate-rules'
import '../../yup'

interceptorsSetup()

const app = createApp(App)
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(
  createGtm({
    id: 'GTM-P7KSW46',
    enabled: location.host.startsWith('app'),
    vueRouter: router,
    vueRouterAdditionalEventData: () => {
      let companyStore = useCompanyStore()
      let globalStore = useGlobalStore()
      let userStore = useUserStore()

      return {
        event: 'pageview',
        userId: userStore.id,
        firstname: userStore.properties.firstname,
        lastname: userStore.properties.lastname,
        email: userStore.email,
        companyId: companyStore.id,
        companyName: companyStore.name,
        isAdmin: globalStore.hasAuthority('ADMIN'),
      }
    },
  }),
)
app.use(VueHotjar, {
  id: 2845999,
  isProduction: true,
  snippetVersion: 6,
})
app.use(autoAnimatePlugin)
app.use(floatingvue, floatingVueOptions)
app.directive('tt', floatingVueTooltipDirectives)

async function bootstrap() {
  try {
    let environment = getEnvironment()
    let sessionSampleRate = 0
    let onErrorSampleRate = environment === 'production' ? 0.7 : 0
    Sentry.init({
      app,
      dsn: 'https://15e8dfa6bc64739d23bafe46d18abc94@o4505347070427136.ingest.sentry.io/4505946558038016',
      environment: environment,
      release: currentVersion,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
        new CaptureConsole({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 0.7,
      replaysSessionSampleRate: sessionSampleRate,
      replaysOnErrorSampleRate: onErrorSampleRate,
    })
  } finally {
    app.mount('#app')
  }
}

bootstrap()
