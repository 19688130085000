import type {Notification} from './NotificationService'
import {safeJsonParse} from '../../utils/utils'

type NotificationForService = Pick<Notification, 'notificationType' | 'payload'>

class NotificationTagService {
  getTagTranslationKey(notification: NotificationForService) {
    if (notification.notificationType === 'SEGMENT') {
      return this.getSegmentTranslationKey(notification)
    }
    if(notification.notificationType === 'ROOMTYPE') {
      return this.getRoomTypeTranslationKey(notification)
    }

    return null
  }

  private getSegmentTranslationKey(notification: NotificationForService) {
    const parsedPayload = safeJsonParse(notification.payload)
    if (typeof parsedPayload === 'object' && 'unmappedSegmentsCount' in parsedPayload) {
      return 'notifications.tag.segment.unmappedSegments'
    }

    return null
  }

  private getRoomTypeTranslationKey(notification: NotificationForService) {
    const parsedPayload = safeJsonParse(notification.payload)
    if(typeof parsedPayload === 'object' && 'unmappedRoomTypesCount' in parsedPayload) {
      return 'notifications.tag.roomType.unmappedRoomTypes'
    }

    return null
  }
}

const notificationTagService = new NotificationTagService()

export default notificationTagService
