export type Nullable<T> = T | null
export type Nil = null | undefined
export type Maybe<T> = T | Nil
export type IdObject = Record<'id', number>
export type Noop = () => void

export enum Excludeable {
  ALL,
  COMPANY_SETUP_CHECK,
  COMPANY_PROPERTIES,
  COMPANY_STORE,
  GLOBAL_STORE,
  USER_STORE,
}
