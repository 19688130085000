<script setup lang="ts">
import MenuItems from './MenuItems.vue'

type Emit = {
  toggleMenu: []
}

interface Props {
  showMenuItems: boolean
}

defineEmits<Emit>()
defineProps<Props>()

const menuClasses = ['group text-left bg-inherit whitespace-nowrap']

const menuItemClasses = [
  'text-base text-brandlight hover:text-white group-hover:text-white',
  'tracking-widest uppercase cursor-pointer antialiased',
]

const subMenuClasses = [
  'absolute p-4 bg-inherit shadow-md flex flex-col gap-y-1',
  'text-base text-brandlight hover:text-white tracking-widest uppercase cursor-pointer antialiased',
  'transform transition ease-in-out duration-75 -translate-y-1/4 group-hover:translate-y-0 scale-y-0 group-hover:scale-y-100 opacity-0 group-hover:opacity-100',
]

const subMenuItemClasses = ['text-brandlight hover:text-white tracking-normal normal-case cursor-pointer antialiased']
</script>

<template>
  <ul v-if="showMenuItems" class="flex flex-row gap-x-6 items-center bg-inherit z-header">
    <MenuItems
      :is-burger-menu="false"
      :menu-classes="menuClasses"
      :menu-item-classes="menuItemClasses"
      :sub-menu-classes="subMenuClasses"
      :sub-menu-item-classes="subMenuItemClasses"
      @toggle-menu="$emit('toggleMenu')"
    ></MenuItems>
  </ul>
  <template v-else>
    <div class="flex flex-grow"></div>
  </template>
</template>
