import {onMounted, ref} from 'vue'
import {isNullOrUndefined} from '../utils/utils'

export function useWindowSize() {
  const width = ref(window.innerWidth)
  const height = ref(window.innerHeight)

  const handler = (e: UIEvent) => {
    if (isNullOrUndefined(e.currentTarget)) {
      return
    }

    const currentTarget = e.currentTarget as Window

    width.value = currentTarget.innerWidth
    height.value = currentTarget.innerHeight
  }

  onMounted(() => {
    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    }
  })

  return [width, height]
}
