<script setup lang="ts">
import {computed, ref} from 'vue'
import {useGlobalStore} from '../stores/global'
import {getEnvironment} from '../utils/getEnvironment'
import {version as currentVersion} from '../../package.json'

const globalStore = useGlobalStore()

const version = ref(`RateBoard ${currentVersion}`)

const classes = computed(() => ({
  'bg-branddark': !globalStore.isSuperAdmin,
  'bg-red': globalStore.isSuperAdmin && getEnvironment() === 'production',
  'bg-devgreen': globalStore.isSuperAdmin && getEnvironment() === 'development',
  'bg-stagingblue': globalStore.isSuperAdmin && getEnvironment() === 'staging',
  'bg-localgrey': globalStore.isSuperAdmin && getEnvironment() === 'localhost',
}))
</script>

<template>
  <footer :class="classes" class="w-full px-10 py-8 md:px-20 text-white text-base">
    <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0">
      <div class="w-full px-1.5 sm:w-1/4">
        <h2 class="mb-2 text-lg font-bold sm:mb-4">{{ $t('footer.support.title') }}</h2>
        <p>
          {{ $t('footer.support.times1') }}
          <br />
          {{ $t('footer.support.times2') }}
        </p>
      </div>

      <div class="w-full px-1.5 sm:w-1/4">
        <h2 class="mb-2 text-lg font-bold sm:mb-4">{{ $t('footer.contact.title') }}</h2>
        <p>
          <a class="text-white hover:text-brandmain" href="mailto:support@rateboard.io">support@rateboard.io</a>
        </p>
      </div>

      <div class="w-full px-1.5 sm:w-1/4">
        <h2 class="mb-2 text-lg font-bold sm:mb-4">{{ $t('footer.documentation.title') }}</h2>
        <p>
          <a
            class="text-brandlight hover:text-brandmain"
            href="https://rateboard-servicedesk.atlassian.net/wiki/spaces/IPH/overview"
            target="_blank"
            title="RateBoard Support"
          >
            {{ $t('footer.documentation.helpdesk') }}
          </a>
        </p>
      </div>

      <div class="w-full px-1.5 sm:w-1/4 sm:text-center sm:order-first">
        <div class="w-fit">
          <img alt="RateBoard" class="w-12 mx-auto sm:w-10" src="/logo_mini_white.svg" v-tt="version" />
          <a class="before:content-['\00a9'] text-white hover:text-brandmain" href="https://www.rateboard.io">RateBoard GmbH </a>
        </div>
      </div>
    </div>
  </footer>
</template>
