<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {useModalStore} from '../stores/modal'
import {useI18n} from 'vue-i18n'

const LAST_ACTIVITY_SIGNAL = 'lastActivitySignal'
const LOGOUT_SIGNAL = 'logoutSignal'

const modalStore = useModalStore()
const {t} = useI18n()

interface Props {
  logoutAfter?: number
  timeoutAfter?: number
}

const props = withDefaults(defineProps<Props>(), {
  logoutAfter: 60,
  timeoutAfter: 15 * 60,
})

const logoutAfterMillis = computed(() => props.logoutAfter * 1000)
const timeoutAfterMillis = computed(() => props.timeoutAfter * 1000)

onMounted(() => {
  enableTracking()
})

function currentTimeInMillis() {
  return new Date().getTime()
}

function disableTracking() {
  document.removeEventListener('click', updateLastActivity)
  document.removeEventListener('scroll', updateLastActivity)
  document.removeEventListener('keydown', updateLastActivity)

  window.removeEventListener('storage', handleStorageEvent)
  window.removeEventListener('message', handleMessage)
}

function doLogout() {
  window.location.replace('/rateboard/logout')
}

function enableTracking() {
  updateLastActivity()
  startTimeoutInterval()

  document.addEventListener('click', updateLastActivity)
  document.addEventListener('scroll', updateLastActivity)
  document.addEventListener('keydown', updateLastActivity)

  window.addEventListener('storage', handleStorageEvent)
  window.addEventListener('message', handleMessage)
}

function handleLogout() {
  localStorage.setItem(LOGOUT_SIGNAL, currentTimeInMillis().toString())
  doLogout()
}

function handleMessage(e: MessageEvent<any>) {
  if (e.data === '[interacted]') {
    updateLastActivity()
  }
}

function handleStorageEvent(event: StorageEvent) {
  if (event.key === LOGOUT_SIGNAL) {
    doLogout()
  }
}

function isTimoutExpired() {
  const item = localStorage.getItem(LAST_ACTIVITY_SIGNAL)
  const lastInteraction = item === null ? 0 : parseInt(item)
  return currentTimeInMillis() >= lastInteraction + timeoutAfterMillis.value
}

function startLogoutInterval() {
  disableTracking()

  const logoutInMillis = currentTimeInMillis() + logoutAfterMillis.value
  const logoutCounter = ref(props.logoutAfter)

  const logoutInterval = setInterval(() => {
    if (isTimoutExpired()) {
      logoutCounter.value = Math.max(Math.ceil((logoutInMillis - currentTimeInMillis()) / 1000), 0)
      if (logoutCounter.value === 0) {
        clearInterval(logoutInterval)
        handleLogout()
      }
    } else {
      clearInterval(logoutInterval)
      modalStore.clearModal()
      enableTracking()
    }
  }, 1000)

  const timeoutLabel = computed(() => {
    return `${t('session.expired.logout')} (${logoutCounter.value})`
  })

  modalStore.addModal(
    t('session.expired.title'),
    t('session.expired.message'),
    {
      callback: () => {
        clearInterval(logoutInterval)
        enableTracking()
      },
      label: t('session.expired.continue'),
    },
    {callback: handleLogout, label: timeoutLabel, variant: 'warning-text'}
  )
}

function startTimeoutInterval() {
  const timeoutInterval = setInterval(() => {
    if (isTimoutExpired()) {
      clearInterval(timeoutInterval)
      startLogoutInterval()
    }
  }, 1000)
}

function updateLastActivity() {
  localStorage.setItem(LAST_ACTIVITY_SIGNAL, currentTimeInMillis().toString())
}
</script>
