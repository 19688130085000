<script setup lang="ts">
import RBButton from '../../components/Button.vue'
import {ref} from 'vue'
import {useDpaStore} from '../../stores/dpa'

const dpaStore = useDpaStore()

const showDialog = ref(false)

function changeLanguage(lang: string) {
  dpaStore.setLanguage(lang)
}

function print() {
  window.print()
}

function onMouseEnter() {
  showDialog.value = true
}

function onMouseLeave() {
  showDialog.value = false
}

function toggleHandler() {
  if (showDialog.value) return
  showDialog.value = !showDialog.value
}
</script>

<template>
  <div class="fixed flex flex-row bg-branddark w-full h-20 items-center z-header">
    <div class="w-5/6">
      <a href="#"><img alt="RateBoard" class="ml-6" src="/logo_mini_white.svg" title="RateBoard" /></a>
    </div>
    <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
      <RBButton class="sm:w-44 text-base" @click="toggleHandler">
        <i class="fa-solid fa-caret-down pr-2"></i>
        <i class="fa fa-globe fa-lg"></i> Language
      </RBButton>
      <transition
        enter-active-class="duration-75 ease-in-out"
        enter-from-class="-translate-y-1/4 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="duration-75 ease-in-out"
        leave-from-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/4 scale-y-0 opacity-0"
      >
        <div v-if="showDialog">
          <div class="absolute flex flex-col w-44 bg-branddark leading-6 text-lg text-white overflow-x-hidden shadow-md">
            <button class="p-2 bg-branddark hover:bg-branddarkdarker" type="button" @click="changeLanguage('en')">English</button>
            <button class="p-2 bg-branddark hover:bg-branddarkdarker" type="button" @click="changeLanguage('de')">Deutsch</button>
          </div>
        </div>
      </transition>
    </div>
    <div>
      <RBButton class="sm:w-44 text-base" variant="primary" @click="print"> <i class="fa fa-print fa-lg"></i> Print </RBButton>
    </div>
  </div>
</template>

<style scoped></style>
