import type {AxiosRequestConfig} from 'axios'
import type {CustomDataInConfig} from '../../types/customAxiosConfig'
import {isNullOrUndefined} from '../../utils/utils'

const CANCEL_ERROR_MESSAGES = [
  'Failed to fetch',
  'cancelled',
  'canceled',
  'Load failed',
  'NetworkError when attempting to fetch resource',
]

function isAbortError(error: any) {
  return !isNullOrUndefined(error) && typeof error === 'object' && CANCEL_ERROR_MESSAGES.includes(error.message)
}

export function silenceAbortError(error: any) {
  if (!isAbortError(error)) {
    throw error
  }
}

export default abstract class AbortControllerService {
  controller?: AbortController = undefined

  protected buildConfig(params?: any, data?: any, customData?: CustomDataInConfig): AxiosRequestConfig {
    const config = {params, signal: this.controller?.signal, customData}
    return data !== undefined ? {...config, data} : config
  }
}
