<script setup lang="ts">
import {computed} from 'vue'
import HotelFilter from './HotelFilter.vue'
import LogoutProfileMenu from './LogoutProfileMenu.vue'
import MenuItems from './MenuItems.vue'
import CompanyButton from './CompanyButton.vue'

type Emit = {
  toggleMenu: []
}

interface Props {
  sidebar: boolean
  showMenu: boolean
  showMenuItems: boolean
}

defineEmits<Emit>()
const props = defineProps<Props>()

const classes = computed(() => ({
  'w-96': props.sidebar,
  'w-full px-5': !props.sidebar,
}))

const menuClasses = computed(() => ({
  'group text-left': true,
  '-translate-y-[20px] opacity-0 duration-500': props.showMenu === false,
  'translate-y-0 opacity-100 duration-500': props.showMenu === true,
}))

const menuItemClasses = computed(() => ({
  'text-base text-brandlight hover:text-white group-hover:text-white': true,
  'tracking-widest uppercase cursor-pointer antialiased': true,
}))

const subMenuClasses = computed(() => ({
  'pl-5 pt-2 flex flex-col gap-y-2': true,
  'text-base text-brandlight hover:text-white tracking-widest uppercase': true,
}))

const subMenuItemClasses = ['text-brandlight hover:text-white tracking-normal uppercase cursor-pointer antialiased']
</script>

<template>
  <ul class="flex flex-col gap-y-5 bg-inherit z-header" :class="classes">
    <template v-if="showMenuItems">
      <MenuItems
        :is-burger-menu="true"
        :menu-classes="menuClasses"
        :menu-item-classes="menuItemClasses"
        :sub-menu-classes="subMenuClasses"
        :sub-menu-item-classes="subMenuItemClasses"
        @toggle-menu="$emit('toggleMenu')"
      ></MenuItems>

      <li><hr class="border-t border-gray-300 w-full" /></li>
    </template>

    <li :class="menuClasses">
      <LogoutProfileMenu :class="menuItemClasses" :light="false" @toggle-menu="$emit('toggleMenu')"></LogoutProfileMenu>
    </li>

    <template v-if="!sidebar">
      <li><hr class="border-t border-gray-300 w-full" /></li>
      <CompanyButton variant="invert" class="w-full"></CompanyButton>
    </template>

    <li><hr class="border-t border-gray-300 w-full" /></li>

    <li :class="menuClasses">
      <HotelFilter :scrollable="false" :light="false" :class="subMenuItemClasses"></HotelFilter>
    </li>
  </ul>
</template>
