<script setup lang="ts">
import type {Maybe} from '../types'
import {useModalStore} from '../stores/modal'
import {computed} from 'vue'

const modalStore = useModalStore()

interface Props {
  id: Maybe<string>
}

const props = defineProps<Props>()

const isVisible = computed(() => modalStore.hasModal && modalStore.hasModalWithId(props.id))
</script>

<template>
  <Teleport v-if="isVisible" to="#modal-dialog-content" class="mt-6">
    <slot />
  </Teleport>
</template>
