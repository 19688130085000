import {defineStore} from 'pinia'
import type {Maybe} from '../types'
import companyService, {type Address} from '../services/CompanyService'
import CompanyPropertiesCookie from '../utils/CompanyPropertiesCookie'

interface State {
  id: Maybe<number>
  name: Maybe<string>
  dpaAccepted: Maybe<boolean>
  currency: Maybe<string>
  timeZone: Maybe<string>
  mainUserId: Maybe<number>
  trustYouId: Maybe<string>
  autopilot: Maybe<boolean>
  initialized: boolean
  address: Maybe<Address>
  marketTrends: boolean
  isCompanyPropertiesCookieSet: boolean
}

const defaultState: State = {
  id: null,
  name: null,
  dpaAccepted: null,
  currency: null,
  timeZone: null,
  mainUserId: null,
  trustYouId: null,
  autopilot: null,
  initialized: false,
  address: null,
  marketTrends: false,
  isCompanyPropertiesCookieSet: false,
}

export const useCompanyStore = defineStore('company', {
  state: () => {
    return {...defaultState}
  },
  getters: {
    hasAutopilot: (state) => {
      return state.autopilot === true
    },
    isCompanySelected(state) {
      return state.id !== null
    },
    supportsMarketTrends(state) {
      return state.marketTrends && state.address?.countryCode === 'IT'
    },
  },
  actions: {
    async initialize() {
      if (!this.initialized) {
        return await Promise.all([this.update()])
      }
    },
    reset() {
      CompanyPropertiesCookie.remove()
      Object.assign(this, defaultState)
    },
    update() {
      const companyProperties = CompanyPropertiesCookie.get()
      if (companyProperties === undefined) {
        this.reset()
        this.isCompanyPropertiesCookieSet = false
        return
      } else {
        this.isCompanyPropertiesCookieSet = true
      }

      const companyId = companyProperties.id
      return Promise.all([companyService.fetchSingle(companyId), companyService.fetchFeatures(companyId)])
        .then((results) => {
          this.id = results[0].id
          this.name = results[0].name
          this.dpaAccepted = results[0].dataPrivacyAgreement?.accepted
          this.currency = results[0].currency
          this.timeZone = results[0].timeZone
          this.mainUserId = results[0].mainUserId
          this.trustYouId = results[0].trustYouId
          this.address = results[0].address
          this.autopilot = results[1].autopilot
          this.marketTrends = results[1].marketTrends
        })
        .then(() => (this.initialized = true))
        .catch((error) => {
          console.error(error)
          this.reset()
        })
    },
  },
})
