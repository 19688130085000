import {defaultApi} from '../axios'

interface Version {
  version: string
}

export class VersionService {
  getVersion(): Promise<Version> {
    return defaultApi.get<Version>('/version.json?rid=' + new Date().getTime()).then((response) => {
      return response.data
    })
  }
}

export default new VersionService()
