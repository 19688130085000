type Callable = (...args: any[]) => any

export default function delayWrapper<T extends Callable>(callback: T, timeoutInMillis = 500, initialDelay = false) {
  let timeoutId: number | undefined
  let lastCalledTime: number | undefined

  return function (...args: Parameters<T>) {
    const currentTime = Date.now()

    let timeout = initialDelay ? timeoutInMillis : 0
    if (!initialDelay && lastCalledTime !== undefined) {
      const timeSinceLastCall = currentTime - lastCalledTime
      timeout = timeSinceLastCall > timeoutInMillis ? 0 : timeoutInMillis
    }

    lastCalledTime = currentTime
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(callback, timeout, args)
  }
}
