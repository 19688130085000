import {toValue, type MaybeRef, computed} from 'vue'
import {useUserStore} from '../stores/user'

export const DATE_FMT: Intl.DateTimeFormatOptions = {year: '2-digit', month: '2-digit', day: '2-digit'}
export const TIME_FMT: Intl.DateTimeFormatOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'}
export const DATE_TIME_FMT: Intl.DateTimeFormatOptions = {...DATE_FMT, ...TIME_FMT}
export const DATE_TIME_ZONE_FMT: Intl.DateTimeFormatOptions = {...DATE_TIME_FMT, timeZoneName: 'short'}

/**
 * Provides a DateTimeFormatter based on given options and stored user properties
 * @param options DateTimeFormatOptions
 * @param useLanguage Optional boolean flag to use language instead of locale (default: user locale)
 * @param locale Optional string of language/locale to use (default: user locale or language)
 */
export function useDateTimeFormatter(
  options: MaybeRef<Intl.DateTimeFormatOptions>,
  useLanguage: MaybeRef<boolean> = false,
  locale?: MaybeRef<string>
) {
  const userStore = useUserStore()

  const defaultLocale = computed(() => (toValue(useLanguage) ? userStore.language : userStore.locale))
  const Formatter = computed(() => Intl.DateTimeFormat(toValue(locale) ?? defaultLocale.value, toValue(options)))

  return [Formatter]
}
