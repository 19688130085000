<!-- needed because corresponding font awesome icon doesn't allow coloring circle and exclamation mark separately -->
<script setup lang="ts">
import {useGlobalStore} from '../../stores/global'
import {computed} from 'vue'

const globalStore = useGlobalStore()

const iconContainerClass = computed(() => ({
  'rounded-full w-5 h-5 flex justify-center items-center text-base': true,
  'bg-brandmain': globalStore.isSuperAdmin,
  'bg-red': !globalStore.isSuperAdmin,
}))
</script>

<template>
  <span :class="iconContainerClass">
    <i class="fa-solid fa-exclamation text-white" />
  </span>
</template>
