import type {useI18n} from 'vue-i18n'
import {useCompanyStore} from '../../stores/company'
import type {Notification} from './NotificationService'
import {safeJsonParse} from '../../utils/utils'

type NotificationForService = Pick<Notification, 'payload' | 'tag'>
type Translator = ReturnType<typeof useI18n>['t']

/**
 * separate service because the cases handled will grow in the future
 */
class NotificationPayloadService {
  formatPayload(notification: NotificationForService, t: Translator) {
    const {id: companyId} = useCompanyStore()

    if (notification.tag === `segmentation-hotel-${companyId}`) {
      return this.handleSegmentationPayload(notification, t)
    }

    if (notification.tag === `roomtype-hotel-${companyId}`) {
      return this.handleUnmappedRoomTypePayload(notification, t)
    }
    return notification.payload
  }

  private handleSegmentationPayload(notification: NotificationForService, t: Translator) {
    const parsedPayload = safeJsonParse(notification.payload)
    if (typeof parsedPayload === 'object' && 'unmappedSegmentsCount' in parsedPayload) {
      const unmappedCount = parsedPayload.unmappedSegmentsCount
      if (unmappedCount === 1) {
        return t('notifications.payload.unmappedSegmentsCount')
      }

      return t('notifications.payload.unmappedSegmentsCount', {count: unmappedCount})
    }

    return notification.payload
  }

  private handleUnmappedRoomTypePayload(notification: NotificationForService, t: Translator) {
    const parsedPayload = safeJsonParse(notification.payload)
    if (typeof parsedPayload === 'object' && 'unmappedRoomTypesCount' in parsedPayload) {
      const unmappedCount = parsedPayload.unmappedRoomTypesCount
      if (unmappedCount === 1) {
        return t('notifications.payload.unmappedRoomTypesCount')
      }

      return t('notifications.payload.unmappedRoomTypesCount', {count: unmappedCount})
    }

    return notification.payload
  }
}

const notificationPayloadService = new NotificationPayloadService()

export default notificationPayloadService