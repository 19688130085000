<script setup>
import {defaultApi} from '@/axios'
import GlobalLoadingBar from '@/components/GlobalLoadingBar.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import SelectDefaultCompany from '@/components/SelectDefaultCompany.vue'
import SessionTimeout from '@/components/SessionTimeout.vue'
import Toast from '@/components/Toast.vue'
import {useGlobalStore} from '@/stores/global'
import {useUserStore} from '@/stores/user'
import {storeToRefs} from 'pinia'
import {ref, watch} from 'vue'
import {version as currentVersion} from '../../../package.json'

const globalStore = useGlobalStore()
const userStore = useUserStore()
const {email} = storeToRefs(userStore)

const REFRESH_EVERY_11_HOURS = 1000 * 60 * 60 * 11

watch(
  () => [globalStore.selectedUsername],
  function () {
    window.charmVersion = currentVersion // needed to be read from app iframe
  }
)

if (location.host.startsWith('app')) {
  watch(email, function (newEmail) {
    if (newEmail != null) {
      window.hsConversationsSettings = {
        loadImmediately: false,
      }
      let _hsq = (window._hsq = window._hsq || [])
      _hsq.push([
        'identify',
        {
          email: newEmail,
        },
      ])
      _hsq.push(['trackPageView'])
      fetchHubspotToken()
      setInterval(() => fetchHubspotToken(), REFRESH_EVERY_11_HOURS)
    }
  })
}

function fetchHubspotToken() {
  const customData = {isExcludedFromLoadingBar: true}
  defaultApi
    .get('/api/user/hubspot-token', {customData})
    .then((response) => {
      window.hsConversationsSettings = {
        identificationEmail: userStore.email,
        identificationToken: response.data.token,
      }

      function onConversationsAPIReady() {
        window.HubSpotConversations.widget.load()
      }

      if (window.HubSpotConversations) {
        onConversationsAPIReady()
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady]
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const props = defineProps({
  initShowLoading: {
    type: Boolean,
    default: false,
  },
})

const showLoading = ref(props.initShowLoading)

watch(
  () => globalStore.pageLoading,
  function (val) {
    showLoading.value = val
  }
)
</script>

<template>
  <ModalDialog></ModalDialog>
  <Toast></Toast>

  <router-view name="Header" class="sticky top-0 left-0 z-header"></router-view>

  <div class="w-full h-[calc(100vh-80px)] flex">
    <main class="overflow-y-auto w-full h-full flex-grow flex flex-col">
      <div class="w-full h-max grow">
        <GlobalLoadingBar class="absolute z-loading" />
        <router-view></router-view>
      </div>

      <router-view name="Footer"></router-view>
    </main>

    <aside class="flex-shrink-0 h-full overflow-y-auto shadow-md"></aside>
  </div>

  <SessionTimeout></SessionTimeout>
  <SelectDefaultCompany></SelectDefaultCompany>
</template>

<style scoped>
@media (max-width: 1366px) {
  aside {
    position: absolute;
    right: 0;
    height: calc(100dvh - 80px);
  }
}
</style>
