import {isNullOrUndefined} from './utils'

type Environment = 'production' | 'staging' | 'development' | 'localhost'

export function getEnvironment(): Environment {
  if (location.hostname.startsWith('app')) return 'production'
  if (location.hostname.startsWith('release')) return 'staging'

  const environment = location.hostname.match(/production|staging|development|localhost/)?.[0]
  if (isNullOrUndefined(environment)) {
    console.error(`${location.hostname} cannot be mapped to an environment`)
    return 'development'
  }

  return environment as Environment
}
