<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {isNullOrUndefined} from '../../utils/utils'
import {useCompanyStore} from '../../stores/company'
import {useGlobalStore} from '../../stores/global'
import {useNotificationsStore} from '../../stores/notifications'
import {useRefreshNotifications} from '../../composable/useRefreshNotifications'
import {useWindowSize} from '../../composable/useWindowSize'
import {getEnvironment} from '../../utils/getEnvironment'
import AccountDropdown from './AccountDropdown.vue'
import BurgerMenu from './BurgerMenu.vue'
import CompanyButton from './CompanyButton.vue'
import HorizontalMenu from './HorizontalMenu.vue'
import NotificationBanner from '../notification/NotificationBanner.vue'
import NotificationBell from '../notification/NotificationBell.vue'
import SystemAlertSidePanel from '../SystemAlertSidePanel.vue'

const companyStore = useCompanyStore()
const globalStore = useGlobalStore()
const notificationsStore = useNotificationsStore()

useRefreshNotifications()

const showBurgerMenu = ref(false)

const [windowSize] = useWindowSize()
const isBurgerMenu = computed(() => windowSize.value < 1200)
const isSidebarBurgerMenu = computed(() => windowSize.value > 600)

const headerClasses = computed(() => ({
  'w-full': true,
  'bg-branddark': !globalStore.isSuperAdmin,
  'bg-red': globalStore.isSuperAdmin && getEnvironment() === 'production',
  'bg-devgreen': globalStore.isSuperAdmin && getEnvironment() === 'development',
  'bg-stagingblue': globalStore.isSuperAdmin && getEnvironment() === 'staging',
  'bg-localgrey': globalStore.isSuperAdmin && getEnvironment() === 'localhost',
}))

const burgerMenuFullClasses = computed(() => ({
  'absolute top-0 left-0 w-full overflow-y-scroll bg-inherit': true,
  'h-0 duration-300': !showBurgerMenu.value,
  'h-screen pt-20 pb-20 duration-500': showBurgerMenu.value,
}))

const burgerMenuSideClasses = computed(() => ({
  'absolute top-20 right-0 w-fit px-5 overflow-y-scroll bg-inherit': true,
  'h-0 duration-300': !showBurgerMenu.value,
  'h-[calc(100vh-5rem)] pt-5 pb-5 duration-500': showBurgerMenu.value,
}))

const headerIconClasses = computed(() => ({
  grow: isBurgerMenu.value,
}))

const showMenuItems = computed(() => {
  if (globalStore.hasAuthority('ADMIN', 'PMS_ADMIN')) return true
  return !isNullOrUndefined(companyStore.mainUserId) && !!companyStore.dpaAccepted
})

watch(isBurgerMenu, (newVal) => {
  if (newVal === false) showBurgerMenu.value = false
})

function onToggleMenu() {
  showBurgerMenu.value = !showBurgerMenu.value
}

function closeSidePanel() {
  notificationsStore.showSidePanel = false
}
</script>

<template>
  <header :class="headerClasses">
    <div class="h-20 flex flex-row gap-x-5 px-5 items-center bg-inherit z-header">
      <!-- Header icon -->
      <div :class="headerIconClasses">
        <a href="/" @click="closeSidePanel">
          <img alt="RateBoard" class="float-left w-32 mx-auto hover:scale-105 duration-300" src="/logo_home.png" title="Home" />
        </a>
      </div>

      <!-- Header menu (LARGE) -->
      <HorizontalMenu
        v-if="!isBurgerMenu"
        class="flex-grow"
        :show-menu-items="showMenuItems"
        @toggle-menu="closeSidePanel"
      ></HorizontalMenu>

      <div class="flex flex-row items-center gap-x-2 min-w-fit">
        <!-- Notification bell -->
        <NotificationBell v-if="globalStore.hasAuthority('HOTEL_ADMIN')"></NotificationBell>

        <!-- Account dropdown menu or company button -->
        <AccountDropdown v-if="!isBurgerMenu" @toggle-menu="onToggleMenu"></AccountDropdown>
        <CompanyButton v-else-if="isSidebarBurgerMenu" @click="onToggleMenu" class="h-20 max-w-[350px]"></CompanyButton>

        <!-- Burger menu -->
        <button
          v-if="isBurgerMenu"
          class="text-white leading-none px-3 py-1 outline-none focus:outline-none"
          type="button"
          @click="onToggleMenu"
        >
          <i class="fas fa-bars text-4xl hover:scale-125 duration-200" :class="{hidden: showBurgerMenu}"></i>
          <i
            v-if="isSidebarBurgerMenu"
            class="fas fa-xmark text-5xl hover:scale-125 duration-200"
            :class="{hidden: !showBurgerMenu}"
          ></i>
        </button>
      </div>

      <!-- Header menu (BURGER) -->
      <template v-if="isBurgerMenu">
        <BurgerMenu
          :class="isSidebarBurgerMenu ? burgerMenuSideClasses : burgerMenuFullClasses"
          :show-menu="showBurgerMenu"
          :show-menu-items="showMenuItems"
          :sidebar="isSidebarBurgerMenu"
          @toggle-menu="onToggleMenu"
        >
        </BurgerMenu>

        <button
          v-if="!isSidebarBurgerMenu && showBurgerMenu"
          class="text-white leading-none px-3 py-1 outline-none focus:outline-none z-header"
          type="button"
          @click="onToggleMenu"
        >
          <i class="fas fa-xmark text-5xl hover:scale-125 duration-200"></i>
        </button>
      </template>
    </div>
  </header>

  <SystemAlertSidePanel />

  <div class="absolute top-[6rem] right-8 drop-shadow-lg hidden md:block">
    <NotificationBanner />
  </div>
</template>
