import type {Page} from '../../types/pagination'
import {defaultApi} from '../../axios'

export interface Notification {
  id: number
  tag: string
  notificationType: string
  payload: string
  dateTime: Date
  seen: boolean
}

export type NotificationFromServer = Omit<Notification, 'dateTime'> & {
  dateTime: string
}

export const LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL = 'hide-notification-banner-until'

const customData = {isExcludedFromLoadingBar: true}

class NotificationService {
  fetchNotificationsPage(userId: number, companyId: number, page = 0) {
    const urlSearchParams = {
      userId,
      companyId,
      page,
    }

    return defaultApi
      .get<Page<NotificationFromServer>>('/api/notification/banner', {params: urlSearchParams})
      .then<Page<Notification>>(({data}) => {
        return {
          ...data,
          content: data.content.map(this.mapToNotification),
        }
      })
  }

  markNotificationAsSeen(id: number) {
    return defaultApi.patch(`/api/notification/banner/${id}`, {seen: true}, {customData})
  }

  /**
   * only checks if the date of the newest fetched notifications is later than the newest in current notifications
   */
  hasFetchedNewerNotifications(currentNotifications: Notification[], fetchedNotifications: Notification[]): boolean {
    const fetchedIsEmpty = fetchedNotifications.length === 0
    const currentIsEmpty = currentNotifications.length === 0
    if (!fetchedIsEmpty && currentIsEmpty) {
      return true
    }
    if (fetchedIsEmpty || currentIsEmpty) {
      return false
    }

    const currentlyNewestNotificationDateTime = currentNotifications[0].dateTime
    const fetchedNotificationsNewestDateTime = fetchedNotifications[0].dateTime

    return fetchedNotificationsNewestDateTime > currentlyNewestNotificationDateTime
  }

  checkIfHideBannerTimeIsOver() {
    const hideUntil = localStorage.getItem(LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL)
    if (hideUntil == null) {
      return true
    }

    const currentTime = new Date().getTime()

    return currentTime > parseInt(hideUntil)
  }

  private mapToNotification(item: NotificationFromServer) {
    return {
      ...item,
      dateTime: new Date(item.dateTime),
    }
  }
}

const notificationService = new NotificationService()
export default notificationService
