import {defineStore} from 'pinia'
import {v4 as uuidv4} from 'uuid'
import type {Maybe} from '../types'
import type {Ref} from 'vue'
import {isNullOrUndefined} from '../utils/utils'

interface ModalAction {
  callback?: () => void | Promise<void>
  label?: Ref | string
  icon?: Ref | string
  variant?: Ref | string
  visible?: Ref | boolean
}

interface SubmitModalAction extends ModalAction {
  enabled?: Ref | boolean
}

interface Modal {
  id: string
  title: string
  message?: string
  submit?: SubmitModalAction
  cancel?: ModalAction
  data?: any
}

type State = {
  currentModal: Maybe<Modal>
}

export const useModalStore = defineStore('modal', {
  state(): State {
    return {
      currentModal: undefined,
    }
  },
  actions: {
    addModal(
      title: string,
      message?: string,
      submit?: SubmitModalAction,
      cancel?: ModalAction,
      data?: any,
      cancelCallbackOnClear = true
    ) {
      this.clearModal(cancelCallbackOnClear)
      this.currentModal = {id: uuidv4(), title, message, submit, cancel, data}
      return this.currentModal.id
    },
    hasModalWithId(id: Maybe<string>) {
      return !isNullOrUndefined(this.currentModal) && this.currentModal.id === id
    },
    clearModal(cancelCallbackOnClear = false) {
      if (cancelCallbackOnClear && this.currentModal?.cancel?.callback) {
        this.currentModal.cancel.callback()
      }

      this.currentModal = null
    },
  },
  getters: {
    hasModal: (state) => !!state.currentModal,
    modalData: (state) => state.currentModal?.data,
  },
})
