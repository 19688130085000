import {defineStore} from 'pinia'

type State = {
  settings: Settings
}

export interface Settings {
  language: string
}

export const useDpaStore = defineStore('dpa', {
  state: (): State => {
    return {
      settings: {
        language: 'en',
      },
    }
  },
  actions: {
    setLanguage(lang: string) {
      this.settings.language = lang
    },
  },
  getters: {
    language: (state) => state.settings.language,
  },
})
