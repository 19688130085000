import {defineStore} from 'pinia'
import AuthService from '../services/Auth/AuthService'
import type {Maybe} from '../types'
import {useLoadingStore} from './loading'

interface GlobalState {
  roles: string[]
  initialized: boolean
  selectedUserId: Maybe<number>
  selectedUsername: Maybe<string>
  passwordResetActive: Maybe<boolean>
}

const ROLES = ['VIEWER', 'USER', 'HOTEL_ADMIN', 'ADMIN', 'SUPER_ADMIN']

const defaultState: GlobalState = {
  roles: [],
  initialized: false,
  selectedUserId: null,
  selectedUsername: null,
  passwordResetActive: null,
}

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {...defaultState}
  },
  getters: {
    __maxRoleIndex: (state) => {
      // returns maximum role index, or -1 if no role is found , or -infty if roles list empty
      return Math.max(...state.roles.map((role) => ROLES.indexOf(role)))
    },
    isSuperAdmin: (state) => {
      return state.roles.includes('SUPER_ADMIN')
    },
    needsToAcceptTerms: (state) => {
      return !(
        state.roles.includes('PMS_ADMIN') ||
        state.roles.includes('IMPORT') ||
        state.roles.includes('SUPER_ADMIN') ||
        state.roles.includes('ADMIN')
      )
    },
  },
  actions: {
    __fetchAndSetCurrentUser() {
      return AuthService.fetchCurrentUser().then((authResponse) => {
        this.selectedUserId = authResponse.id
        this.selectedUsername = authResponse.username
        this.roles = authResponse.roles
        this.passwordResetActive = authResponse.passwordResetActive
      })
    },
    hasAuthority(...roles: string[]) {
      return roles.some((role) => {
        switch (role) {
          case 'TESTING':
          case 'TEST_IMPORT':
          case 'PMS_ADMIN':
          case 'IMPORT':
            return this.roles.includes(role)
          default:
            // if role is in ROLES then ROLES.indexOf(role) >!= 0
            return ROLES.includes(role) && ROLES.indexOf(role) <= this.__maxRoleIndex
        }
      })
    },
    async initialize() {
      AuthService.scheduleRefresh()

      if (!this.initialized) {
        const loadingStore = useLoadingStore()
        const handleMessage = (e: any) => {
          if (e.data === '[loaded]') {
            loadingStore.isLegacyLoading = false
          }
        }
        window.removeEventListener('message', handleMessage)
        window.addEventListener('message', handleMessage)

        await this.__fetchAndSetCurrentUser()
          .then(() => (this.initialized = true))
          .catch((error) => {
            this.reset()
            throw error
          })
      }
    },
    reset() {
      Object.assign(this, defaultState)
    },
  },
})
