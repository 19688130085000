<script setup lang="ts">
import {computed} from 'vue'
import {useGlobalStore} from '../stores/global'
import {useNotificationsStore} from '../stores/notifications'
import Notification from './notification/Notification.vue'
import Button from './Button.vue'
import {useI18n} from 'vue-i18n'

const globalStore = useGlobalStore()
const notificationStore = useNotificationsStore()
const {t} = useI18n()

const bannerClassName = computed(() => ({
  banner: true,
  'bg-branddark': !globalStore.isSuperAdmin,
  'bg-red': globalStore.isSuperAdmin,
}))

function closeBanner() {
  notificationStore.showSidePanel = false
}
</script>

<template>
  <section v-if="notificationStore.showSidePanel" :class="bannerClassName">
    <div class="w-full flex flex-wrap justify-between items-center h-fit">
      <p class="text-white inline-block text-4xl">{{ t('notifications.systemAlerts') }}</p>
      <i class="fa-solid fa-xmark text-white mr-2 md:mr-[initial] text-6xl md:text-3xl cursor-pointer" @click="closeBanner" />
      <div
        v-if="notificationStore.notifications.length !== 0"
        v-for="notification of notificationStore.notifications"
        class="notification-container w-full"
        :key="notification.id"
      >
        <Notification
          :id="notification.id"
          :date-time="notification.dateTime"
          :payload="notification.payload"
          :tag="notification.tag"
          :type="notification.notificationType"
        />
      </div>
      <div v-else class="w-full pt-4 flex items-center space-x-1.5">
        <i class="fa-solid fa-circle-check text-white text-xl" />
        <span class="text-white text-base">{{ t('notifications.panel.empty') }}</span>
      </div>
      <div class="w-full flex items-center justify-center" v-if="notificationStore.hasNextPage">
        <Button variant="primary" label="Load more" @click="notificationStore.fetchMore()" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.banner {
  @apply h-screen top-0 z-toast
  md:h-[calc(100vh-5rem)] md:top-[initial]
  absolute right-0 w-full
  md:w-[29%] overflow-y-scroll flex
  flex-wrap
  pl-10 pr-8 pb-2 pt-4;
}
.notification-container:first-of-type {
  @apply pt-4;
}
.notification-container:not(:last-of-type) {
  border-bottom-color: rgba(237, 237, 237, 0.2);
  @apply pb-4 border-b-[0.5px];
}
.notification-container:not(:first-of-type) {
  @apply pt-4;
}
</style>
