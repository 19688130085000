import {defineStore} from 'pinia'
import type {Notification} from '../services/Notification/NotificationService'
import notificationService, {LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL} from '../services/Notification/NotificationService'
import {isNullOrUndefined} from '../utils/utils'
import {ref} from 'vue'
import {useGlobalStore} from './global'
import {useCompanyStore} from './company'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Notification[]>([])
  const pageNumber = ref(0)
  const hasNextPage = ref(false)
  const showSidePanel = ref(false)
  const showBanner = ref(false)
  const initialized = ref(false)
  const hasFetchedAtLeastOnce = ref(false)

  const globalStore = useGlobalStore()

  function reset() {
    notifications.value = []
    pageNumber.value = 0
    hasNextPage.value = false
    showSidePanel.value = false
    showBanner.value = false
    initialized.value = false
    hasFetchedAtLeastOnce.value = false
  }

  const companyStore = useCompanyStore()

  async function update() {
    if (!globalStore.hasAuthority('HOTEL_ADMIN')) {
      return
    }

    if (isNullOrUndefined(globalStore.selectedUserId) || isNullOrUndefined(companyStore.id)) {
      return
    }

    pageNumber.value = 0 // always fetch the first page when update is called

    await notificationService
      .fetchNotificationsPage(globalStore.selectedUserId, companyStore.id, pageNumber.value)
      .then((response) => {
        handleBannerVisibilityAfterFetch(response.content)
        notifications.value = response.content
        hasNextPage.value = !response.last
        pageNumber.value += 1
        hasFetchedAtLeastOnce.value = true
      })
      .catch((error) => {
        reset()
        throw error
      })
  }

  /**
   * hide banner if no notifications were fetched
   * show banner if a newer notification was fetched
   * show banner if delay time is over and some notifications were fetched
   */
  function handleBannerVisibilityAfterFetch(fetchedNotifications: Notification[]): void {
    if (fetchedNotifications.length === 0) {
      showBanner.value = false
    } else if (
      hasFetchedAtLeastOnce.value && // only check if new notifications arrived if notifications got already fetched once, else it will always return true, because on the first fetch the currently stores notification are always empty
      notificationService.hasFetchedNewerNotifications(notifications.value, fetchedNotifications) &&
      !showSidePanel.value
    ) {
      showBanner.value = true
      localStorage.removeItem(LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL)
    } else if (notificationService.checkIfHideBannerTimeIsOver() && fetchedNotifications.length !== 0 && !showSidePanel.value) {
      showBanner.value = true
      localStorage.removeItem(LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL)
    }
  }

  /**
   * just fetches more notifications, doesn't and shouldn't handle the banner visibility
   */
  async function fetchMore() {
    if (!hasNextPage.value || isNullOrUndefined(globalStore.selectedUserId) || isNullOrUndefined(companyStore.id)) {
      return
    }

    await notificationService
      .fetchNotificationsPage(globalStore.selectedUserId, companyStore.id, pageNumber.value)
      .then((response) => {
        notifications.value.push(...response.content)
        hasNextPage.value = !response.last
        pageNumber.value += 1
      })
      .catch((error) => {
        reset()
        throw error
      })
  }

  async function initialize() {
    if (initialized.value) {
      return
    }

    await update()
    initialized.value = true
  }

  async function removeNotification(id: number) {
    await notificationService.markNotificationAsSeen(id)
    notifications.value = notifications.value.filter((notification) => notification.id !== id)
  }

  return {
    notifications,
    showSidePanel,
    showBanner,
    initialized,
    hasNextPage,
    initialize,
    update,
    removeNotification,
    reset,
    fetchMore,
  }
})
