<script setup lang="ts">
import {useNotificationsStore} from '../../stores/notifications'
import {LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL} from '../../services/Notification/NotificationService'
import {useI18n} from 'vue-i18n'
import {computed} from 'vue'

const BANNER_DELAY_TIME = 3_600_000 // 1 hour

const notificationsStore = useNotificationsStore()
const {t, locale} = useI18n()

const containerClassName = computed(() => ({
  'flex flex-wrap w-[325px] bg-red p-3 z-20 relative': true,
  // italian and german text is longer than english
  'w-[425px]': locale.value === 'it',
  'w-[375px]': locale.value === 'de',
}))

function handleClickSolveNow() {
  notificationsStore.showBanner = false
  notificationsStore.showSidePanel = true
}

/**
 * hide banner and set delay time in localStorage
 */
function handleClickLater() {
  const hideUntil = new Date().getTime() + BANNER_DELAY_TIME

  localStorage.setItem(LOCAL_STORAGE_KEY_HIDE_NOTIFICATION_BANNER_UNTIL, hideUntil.toString())
  notificationsStore.showBanner = false
}
</script>

<template>
  <div v-if="notificationsStore.showBanner" :class="containerClassName">
    <div class="w-[10%] flex items-start">
      <div class="border border-white rounded-full w-8 h-8 flex items-center justify-center">
        <i class="fa-regular fa-bell text-white text-2xl" />
      </div>
    </div>
    <div class="w-[90%] flex flex-wrap mt-1 pl-3">
      <p class="text-white text-xl font-bold">{{ t('notifications.banner.title') }}</p>
    </div>
    <div class="w-full flex justify-end space-x-4 mt-3">
      <span class="action-text" @click="handleClickSolveNow">{{ t('notifications.solveNow') }}</span>
      <span class="action-text" @click="handleClickLater">{{ t('notifications.banner.later') }}</span>
    </div>
  </div>
</template>

<style scoped>
.action-text {
  @apply text-lightgrey text-sm uppercase
  font-bold hover:text-white cursor-pointer;
}
</style>
