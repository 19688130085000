import {defaultApi} from '../axios'
import type {Page} from '../types/pagination'
import AbortControllerService from './abort/AbortControllerService'

export type Frequency = 'NEVER' | 'DAILY' | 'WEEKLY' | 'MONTHLY'

export interface UserSettings {
  language: string | null
  locale: string | null
  showCurrencySymbol: boolean
  rateReport: Frequency
  pickupReport: Frequency
  performanceReport: Frequency
  defaultCompanyId: number | null
}

export interface UserProperties {
  id: number | null
  email: string | null
  username: string | null
  firstname: string | null
  lastname: string | null
  termsAccepted: boolean | null
  settings: UserSettings
}

export class UsersService extends AbortControllerService {
  fetchUser(userId: number): Promise<UserProperties> {
    return defaultApi.get(`/api/users/${userId}`, this.buildConfig()).then((response) => {
      return response.data
    })
  }

  fetchPaginated(
    page: number,
    size: number,
    sort?: string,
    ascending?: boolean,
    ids?: number[],
    username?: string,
    firstname?: string,
    lastname?: string,
    email?: string
  ): Promise<Page<UserProperties>> {
    const joinedIds = ids && ids.join(',')
    const sortWithDirection = sort ? `${sort},${ascending ? 'asc' : 'desc'}` : undefined
    const params = {page, size, sort: sortWithDirection, ids: joinedIds, username, firstname, lastname, email}
    return defaultApi.get<Page<UserProperties>>('/api/user/users', this.buildConfig(params)).then((response) => response.data)
  }

  updateUserSettings(userId: number, settings: UserSettings): Promise<UserSettings> {
    return defaultApi.post(`/api/users/${userId}/settings`, settings).then((response) => response.data)
  }
}

export default new UsersService()
