import {setLocale} from 'yup'

setLocale({
  mixed: {
    required: 'validation.required',
  },
  string: {
    max: 'validation.max',
  },
})
