import 'floating-vue/style.css'
import '@/assets/css/floatingvue-themes.css'

import type {Directive} from 'vue'
import {vTooltip} from 'floating-vue'
import type {FloatingVueConfig} from 'node_modules/floating-vue/dist/config'

export const floatingVueOptions: {themes: Record<string, FloatingVueConfig>} = {
  themes: {
    Rateboard: {
      $extend: 'tooltip',
      popperTriggers: ['hover', 'focus', 'touch', 'click'],
    },
    'dropdown-select': {
      $extend: 'menu',
      delay: {
        show: 200,
        hide: 0,
      },
      distance: 0,
      autoSize: true,
    },
  },
}

export const floatingVueTooltipDirectives: Directive = {
  beforeMount(el, binding) {
    binding = {...binding, value: {content: binding.value, theme: 'Rateboard'}}
    vTooltip.beforeMount(el, binding)
  },

  updated(el, binding) {
    binding = {...binding, value: {content: binding.value, theme: 'Rateboard'}}
    vTooltip.updated(el, binding)
  },

  beforeUnmount(el) {
    vTooltip.beforeUnmount(el)
  },
}
