import {onMounted} from 'vue'
import {useNotificationsStore} from '../stores/notifications'

const FETCH_INTERVAL = 300_000 // 5 minutes

export function useRefreshNotifications() {
  const notificationsStore = useNotificationsStore()

  onMounted(() => {
    notificationsStore.initialize()
    setInterval(() => {
      notificationsStore.update()
    }, FETCH_INTERVAL)
  })
}
